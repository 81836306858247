// import { scan } from 'react-scan';
import React from 'react';
import ReactDOM from 'react-dom/client';
import Interface from './Components/Interface';

// if (typeof window !== 'undefined') {
//     scan({
//       enabled: true,
//       log: true, // logs render info to console (default: false)
//     });
//   }

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Interface />);