import { memo } from 'react';
import Pressable from "../../../other/Pressable"

const BotoAjuntament = memo(({ socket, ajuntament, setAjuntament, hideAjuntament, setHideAjuntament, disabled, room }) => {
    const rotate = () => {
        setHideAjuntament(false)
        const newVal = (ajuntament+1)%4
        socket.emit('.rotate_ajuntament_to_room', room, newVal)
        setAjuntament(newVal)
    }

    return ajuntament !== null && (
        <Pressable 
            onClick={rotate} 
            id="boto-ajuntament-container" 
            style={{ transform: `rotate(${ajuntament*90}deg)`, backgroundColor: 'rgba(0, 255, 0, 0.2)' }} 
            className={`boto boto-ajuntament ${disabled ? 'disabled' : ''}`}
        >
            <div>🏛️</div>
        </Pressable>
    );
}, (prevProps, nextProps) => 
    prevProps.disabled === nextProps.disabled &&
    prevProps.ajuntament === nextProps.ajuntament &&
    prevProps.room === nextProps.room
);

export default BotoAjuntament;