import React, { useState, memo, useMemo } from 'react';

const Pressable = memo(({ _onPressColor, style, href, ...attrs }) => {
  const [pressed, setPressed] = useState(false);
  const onPressColor = _onPressColor || '#ccc';

  const bgColor = useMemo(() => {
    return pressed ? onPressColor :
      style?.backgroundColor ? style?.backgroundColor :
      'transparent'
  }, [pressed, onPressColor, style?.backgroundColor]);

  const press = () => {
    setPressed(true);
  };

  const unpress = () => {
    setPressed(false);
  };

  const commonStyles = useMemo(() => ({
    ...style,
    backgroundColor: bgColor,
    userSelect: 'none',
    cursor: 'pointer'
  }), [style, bgColor]);

  const linkStyles = useMemo(() => ({
    textDecoration: 'none',
    color: '#000',
    ...commonStyles
  }), [commonStyles]);

  return href ? (
    <a
      href={href}
      style={linkStyles}
      onPointerDown={press}
      onPointerUp={unpress}
      onPointerLeave={unpress}
      onPointerCancel={unpress}
      onPointerOut={unpress}
      {...attrs}
    />
  ) : (
    <div
      onPointerDown={press}
      onPointerUp={unpress}
      onPointerLeave={unpress}
      onPointerCancel={unpress}
      onPointerOut={unpress}
      style={commonStyles}
      {...attrs}
    />
  );
});

export default Pressable;