import { memo } from 'react';
import Pressable from "../../../other/Pressable";

const BotoUndo = memo(({ socket, posicionsLog, room }) => {
    const disabled = posicionsLog?.length < 1

    const undo = () => {
        if (disabled) return;
        socket.emit('.undo_change_to_room', room);
    }

    return (
        <Pressable id="undo-container"
            onClick={undo}
            className={`boto boto-undo ${disabled ? 'disabled' : ''}`}
            style={{
                backgroundColor: 'rgba(200, 87, 0, 0.2)'
            }}
        >
            &#10554;
        </Pressable>
    );
}, (prevProps, nextProps) => 
    prevProps.posicionsLog?.length === nextProps.posicionsLog?.length &&
    prevProps.room === nextProps.room
);

export default BotoUndo;