import { memo } from "react";
import Pressable from "../../../other/Pressable";

const BotoAssistencia = memo(({ disabled, targetEvent, setPopupClosed }) => {
    return (
        <Pressable 
            onClick={() => setPopupClosed(false)} 
            style={{ backgroundColor: 'rgba(255, 175, 0, 0.2)' }} 
            className={`boto boto-assist ${disabled ? 'disabled' : ''} ${targetEvent ? 'withTarget' : ''}`}
        >
            <div>&#128101;</div>
        </Pressable>
    )
}, (prevProps, nextProps) => 
    prevProps.disabled === nextProps.disabled && 
    prevProps.targetEvent === nextProps.targetEvent
);

export default BotoAssistencia;