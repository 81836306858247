import React, { useState, useEffect, useRef } from 'react';
import ChatModal from './ChatModal';
import { fetchAPI } from '../../../utils/utils';

const ChatButton = React.memo(({ selectedEvent, selectedBundle, selectedVersio, castellersInfo, ...props }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [messages, setMessages] = useState([]);
    const chatRef = useRef(null);

    useEffect(() => {
        const fetchMessages = () => {
            fetchAPI(
                `/missatges/${selectedEvent}/${selectedBundle}/${selectedVersio}`,
                (data) => setMessages(data)
            );
        };

        fetchMessages();
        const intervalId = setInterval(fetchMessages, 5000); // Fetch every 5 seconds

        return () => clearInterval(intervalId);
    }, [selectedEvent, selectedBundle, selectedVersio]);

    useEffect(() => {
        if (chatRef.current) {
            chatRef.current.scrollTop = chatRef.current.scrollHeight;
        }
    }, [messages]);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const displayName = (user) => {
        const castellerInfo = castellersInfo?.[user]
        if (castellerInfo?.mote) return castellerInfo.mote
        return castellerInfo?.nom + ' ' + castellerInfo?.cognom[0] + '.'
    }

    const hasMessages = messages.length > 0;

    return (
        <>
            <div
                ref={chatRef}
                onClick={handleOpenModal}
                style={{
                    position: 'absolute',
                    top: 75,
                    left: 15,
                    width: hasMessages ? 150 : 'auto',
                    minHeight: hasMessages ? 25 : 'auto',
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    borderRadius: 10,
                    padding: hasMessages ? 10 : 10,
                    overflowY: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    fontSize: 12,
                    cursor: 'pointer',
                    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                    alignItems: hasMessages ? 'flex-start' : 'center',
                    justifyContent: hasMessages ? 'flex-start' : 'center',
                    cursor: 'pointer',
                }}
            >
                {hasMessages ? (
                    <>
                        {messages.length > 3 && (
                            <div style={{ marginBottom: 5, fontStyle: 'italic' }}>
                                {messages.length - 3} missatges més...
                            </div>
                        )}
                        {messages.slice(-3).map((message, index) => (
                            <div key={index} style={{ marginBottom: 5 }}>
                                <strong>{displayName(message.user)}:</strong> {message.content}
                            </div>
                        ))}
                        <div style={{
                            position: 'absolute',
                            bottom: 5,
                            right: 5,
                            fontSize: 18,
                            opacity: 0.7,
                            cursor: 'pointer',
                        }}>
                            💬
                        </div>
                    </>
                ) : (
                    <div style={{ fontSize: 16, cursor: 'pointer' }}>💬</div>
                )}
            </div>
            {isModalOpen && (
                <ChatModal 
                    onClose={handleCloseModal} 
                    selectedEvent={selectedEvent}
                    selectedBundle={selectedBundle}
                    selectedVersio={selectedVersio}
                    castellersInfo={castellersInfo}
                    {...props}
                />
            )}
        </>
    );
}, (prevProps, nextProps) => 
    prevProps.selectedEvent === nextProps.selectedEvent &&
    prevProps.selectedBundle === nextProps.selectedBundle &&
    prevProps.selectedVersio === nextProps.selectedVersio &&
    JSON.stringify(prevProps.castellersInfo) === JSON.stringify(nextProps.castellersInfo)
);

export default ChatButton;