import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {isBrowser, isDesktop, isMobile} from 'react-device-detect';
import BotoExportar from './botons/BotoExportar';
import BotoUndo from './botons/BotoUndo';
import LlistaCastellers from './LlistaCastellers';
import './Controller.css'

import { fetchAPI } from '../../../utils/utils';

import Swiper from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import BotoAssistencia from './botons/BotoAssistencia';
import BotoAjuntament from './botons/BotoAjuntament';
import LlistaAssistencies from '../../interface/assistencia/LlistaAssistencies';
import BotoChat from './botons/BotoChat';
import ChatPopup from '../../chat/ChatPopup';
import BotoPestanya from './botons/BotoPestanya';
import BotoPestanyaUp from './botons/BotoPestanyaUp';
import BotoPestanyaDown from './botons/BotoPestanyaDown';
import BotoImportar from './botons/BotoImportar';

const normalize = (str) => {
    return String(str)
        .normalize("NFD").replace(/[\u0300-\u036f]/g, "")    
        .toLowerCase();
};

const levenshteinDistance = (a, b) => {
    const matrix = [];
    let i, j;

    if (a.length == 0) return b.length;
    if (b.length == 0) return a.length;

    for (i = 0; i <= b.length; i++) {
        matrix[i] = [i];
    }

    for (j = 0; j <= a.length; j++) {
        matrix[0][j] = j;
    }

    for (i = 1; i <= b.length; i++) {
        for (j = 1; j <= a.length; j++) {
            if (b.charAt(i-1) == a.charAt(j-1)) {
                matrix[i][j] = matrix[i-1][j-1];
            } else {
                matrix[i][j] = Math.min(matrix[i-1][j-1] + 1, Math.min(matrix[i][j-1] + 1, matrix[i-1][j] + 1));
            }
        }
    }

    return matrix[b.length][a.length];
};

const searchMatches = (casteller, str, maxDistance = 2) => {
    const fullName = `${casteller.nom} ${casteller.cognom} ${casteller.mote}`;
    const normalizedFullName = normalize(fullName);
    const normalizedStr = normalize(str);
    let totalDistance = 0;

    if (str.length > 0) {
        const words = normalizedFullName.split(/\s+/); // Split by whitespace
        const searchWords = normalizedStr.split(/\s+/); // Split search string by whitespace

        for (let searchWord of searchWords) {
            let closestDistance = Infinity;
            
            for (let word of words) {
                const distance = levenshteinDistance(word.slice(0,Math.min(word.length, searchWord.length)), searchWord);
                closestDistance = Math.min(closestDistance, distance);
            }

            totalDistance += closestDistance;
        }

        return totalDistance <= maxDistance;
    }
    return true;
};

const sortMatches = (casteller, str) => {
    const mote = casteller.mote || '';
    const nom = casteller.nom || '';
    const cognom = casteller.cognom || '';

    // Function to calculate the score based on the type of match
    const calculateScore = (fieldValue, query) => {
        const normalizedField = normalize(fieldValue);
        const normalizedQuery = normalize(query);

        if (normalizedField === '' || normalizedQuery === '') {
            return 1000; // No match
        }

        if (normalizedField === normalizedQuery) {
            return 1; // Exact match
        } else if (normalizedField.startsWith(normalizedQuery) || normalizedQuery.startsWith(normalizedField)) {
            return 2; // Start of word match
        } else if (normalizedField.includes(normalizedQuery) || normalizedQuery.includes(normalizedField)) {
            return 3; // Included match
        }
        return 1000; // No match
    };

    const moteScore = calculateScore(mote, str);
    const nomScore = calculateScore(nom, str);
    const cognomScore = calculateScore(cognom, str);

    // Adjust scores to prioritize mote over nom, and nom over cognom
    const adjustedMoteScore = moteScore * 10;
    const adjustedNomScore = nomScore * 100;
    const adjustedCognomScore = cognomScore * 1000;

    return Math.min(adjustedMoteScore, adjustedNomScore, adjustedCognomScore);
}

const saveChange = (props) => {
    const { socket, castellerSelected, caixaSelected, posicions } = props;

    const room = `${props.selectedEvent}.${props.selectedBundle}.${props.selectedVersio}`;
    const action_id = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

    // If casteller was already in a caixa, remove it
    if (castellerSelected in posicions.castellers) {
        socket.emit('.save_change_to_room', room, `${posicions.castellers[castellerSelected]},_EMPTY_`, action_id);
    }

    // Emit change to server-side (bounces to all users)
    socket.emit('.save_change_to_room', room, `${caixaSelected},${castellerSelected}`, action_id);
};

const erasePosition = (props) => {
    const { castell, socket, caixaSelected, setCaixaSelected } = props;
    const room = `${props.selectedEvent}.${props.selectedBundle}.${props.selectedVersio}`;
    const action_id = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    socket.emit('.save_change_to_room', room, `${caixaSelected},_EMPTY_`, action_id);
    setCaixaSelected(-1);
};

const backToNormal = (props, setNameSearch, setShowBuscar) => {
    const { setCastellerSelected, setCaixaSelected } = props;

    // Reset search params
    setNameSearch('')
    setShowBuscar(false)

    // Deselect selected casteller and caixa
    setCastellerSelected(-1);
    setCaixaSelected(-1);
};

const confirmatsFilter = casteller => (casteller["assistència"] === 1 || casteller["assistència"] === 2) && parseInt(casteller.canalla) === 0
const noConfirmatsFilter = casteller => casteller["assistència"] !== 0 && casteller["assistència"] !== 1 && casteller["assistència"] !== 2 && parseInt(casteller.canalla) === 0
const noVenenFilter = casteller => casteller["assistència"] === 0 && parseInt(casteller.canalla) === 0
const canallaFilter = casteller  => parseInt(casteller.canalla) === 1
const novellFilter = casteller => !!casteller?.mote?.includes('#')
const amagatFilter = casteller => parseInt(casteller.hidden) === 1

function Controller(props) {
    const { setImporting, setLastLlista, assistenciesEvent, isModel, tabs, pestanya, setPestanya, socket, extended, setExtended, ajuntament, setAjuntament, castellerSelected, posicions, lastCaixes, caixaSelected, swiper, setSwiper, castell, hoveredUser, setHoveredUser } = props;

    const [popupAssistsClosed, setPopupAssistsClosed] = useState(true);
    const [popupChatClosed, setPopupChatClosed] = useState(true);

    const [nameSearch, setNameSearch] = useState('')
    const [showBuscar, setShowBuscar] = useState(false)
    const [isFocused, setIsFocused] = useState(false)

    const [etiquetes, setEtiquetes] = useState([]);
    const [etiquetaUsers, setEtiquetaUsers] = useState([]);

    const [activeSlide, setActiveSlide] = useState(false);

    const castellers = useMemo(() => 
        Object.values(props.assistenciesEvent || {})
            // Don't show hidden
            .filter(c => (nameSearch === '' && props.name !== 'amagats') ? (parseInt(c.hidden) !== 1 || [1, 2].includes(c['assistència'])) : true)
            // Filtrar per nom
            .filter(casteller => searchMatches(casteller, nameSearch))
            // Sort remaining
            .sort((a, b) => a.altura > b.altura ? 1 : -1)
            // Sort matches if searching
            .sort((a, b) => nameSearch !== '' ? (sortMatches(a, nameSearch) > sortMatches(b, nameSearch) ? 1 : -1) : 0)
    , [props.assistenciesEvent, nameSearch]);

    useEffect(() => {
        setSwiper(new Swiper(".controllerSwiper", {
            threshold: 20,
            initialSlide: 0,
            allowTouchMove: true,

            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        }));
    }, []);

    useEffect(() => {
        if (swiper) {
            swiper.on('transitionStart', function() {
                // If slide is not 0, extend
                setExtended(swiper.realIndex > 0)

                // Set active slide
                setActiveSlide(swiper.realIndex)

                // Aesthetic change
                setTimeout(
                    () => {
                        document.querySelector('.activa')?.classList.remove('activa')
                        document.querySelector('.swiper-slide.swiper-slide-active').querySelector('.castellers-container')?.classList.add('activa')
                    },
                    50
                )
            });

            swiper.on('transitionEnd', function() {
                if (swiper.realIndex > 0) {
                    setLastLlista(swiper.realIndex)
                }
            });
        }
    }, [swiper])

    useEffect(() => {
        fetchAPI('/etiquetes', setEtiquetes)
    }, [])

    useEffect(() => {
        etiquetes.forEach(etiqueta => {
            fetchAPI(
                `/etiqueta_users/${etiqueta.id}`,
                users => setEtiquetaUsers(
                    prev => ({
                        ...prev,
                        [etiqueta.id]: {
                            castellers: users,
                            perfil: etiqueta.nom
                        }
                    })
                )
            )
        })
    }, [
        etiquetes
    ])

    const handleKeyDown = (e) => {
        if (isFocused) return;
        if (isMobile) return;

        if (extended && caixaSelected !== -1) {
            // If key is backspace, remove last letter from nameSearch
            if (e.key === 'Backspace') {
                setNameSearch(prev => prev.slice(0, -1))
            }
            // If key is enter, search for casteller
            else if (e.key === 'Enter') {
                const castellers = document.querySelectorAll('.castellers-container.activa .casteller');
                const castellersArray = Array.from(castellers);
    
                // const casteller = castellersArray.find(casteller => casteller.innerText.toLowerCase().includes(nameSearch.toLowerCase()));
                const casteller = castellersArray?.[0]

                if (casteller && nameSearch !== '') {
                    casteller.click();
                }
            }
            // If key is escape, close search
            else if (e.key === 'Escape') {
                backToNormal(props, setNameSearch, setShowBuscar);
            }
            // // If key is space, add space to nameSearch
            // else if (e.key === ' ') {
            //     setNameSearch(prev => prev + ' ')
            // }
            // // If key is a letter, add it to nameSearch
            // else if (e.key.match(/[a-z]/i) && e.key.length === 1) {
            //     setNameSearch(prev => prev + e.key)
            //     setShowBuscar(true)
            // }
        }

        if (caixaSelected !== -1) {
            // If key is delete, remove casteller from caixa
            if (e.key === 'Delete') {
                erasePosition(props);
            }
        }
    }

    useEffect(() => {
        document.addEventListener('keyup', handleKeyDown)

        return () => {
            document.removeEventListener('keyup', handleKeyDown)
        }
    }, [extended, caixaSelected, isFocused, nameSearch])

    useEffect(() => {
        if (castellerSelected > -1 && caixaSelected !== -1) {
            saveChange(props);
            backToNormal(props, setNameSearch, setShowBuscar);
        }
    }, [castellerSelected, caixaSelected]);

    useEffect(() => {
        const caixaEmpty = !(caixaSelected in posicions.caixes);

        const areSame = lastCaixes.prev === lastCaixes.current;
        const areBothCaixes = lastCaixes.prev !== -1 && lastCaixes.current !== -1;
        const prevHadAssigned = lastCaixes.prev in posicions.caixes;
        const possibleSwap = !areSame && areBothCaixes && prevHadAssigned;

        if (caixaSelected !== -1 && caixaEmpty && !possibleSwap) {
            setExtended(true)
            if (swiper && swiper.realIndex === 0) swiper.slideTo(1, 0);
        } else {
            // setExtended(false)
            // if (swiper) swiper.slideTo(0, 0);
        }
    }, [lastCaixes]);

    const nNovells = Object
        .values(props.castellersInfo)
        .filter(novellFilter)
        .length;

    const filterFamilies = (c) => c.casteller === 0 && c.music === 0

    return (
        <>
            <LlistaAssistencies noInfo withTarget etiquetes={etiquetes} etiquetaUsers={etiquetaUsers} setEtiquetaUsers={setEtiquetaUsers} assistencies={assistenciesEvent || []} popupClosed={popupAssistsClosed} setPopupClosed={setPopupAssistsClosed} event={props.selectedEvent} {...props} />
            <ChatPopup popupClosed={popupChatClosed} setPopupClosed={setPopupChatClosed} {...props} />

            <div id="controller-container" className={`swiper controllerSwiper ${extended ? 'extended' : ''}`}>
                <div className="swiper-wrapper">
                    <div className="swiper-slide botons">
                        {
                            !isModel && <>
                                <BotoUndo room={`${props.selectedEvent}.${props.selectedBundle}.${props.selectedVersio}`} {...props} />
                                {/* <BotoExportar {...props} /> */}
                                <BotoImportar setImporting={setImporting} />
                                <BotoAssistencia setPopupClosed={setPopupAssistsClosed} {...props} />
                                <BotoAjuntament ajuntament={ajuntament} room={`${props.selectedEvent}.${props.selectedBundle}.${props.selectedVersio}`} {...props} />
                                {/* { isBrowser && <BotoChat setPopupClosed={setPopupChatClosed} {...props} /> } */}   
                            </>
                        }
                        <BotoPestanyaUp full={false} tabs={tabs} pestanya={pestanya} setPestanya={setPestanya} {...props} />
                        <BotoPestanyaDown full={false} tabs={tabs} pestanya={pestanya} setPestanya={setPestanya} {...props} />
                    </div>

                    {
                        !isModel ? <>
                            <div className="swiper-slide llista-container castellers-confirmats">
                                <LlistaCastellers isModel={isModel} slideNum={1} name="etiquetes" etiquetaUsers={etiquetaUsers} etiquetes={etiquetes} filter={confirmatsFilter} {...props} nameSearch={nameSearch} setNameSearch={setNameSearch} showBuscar={showBuscar} setShowBuscar={setShowBuscar} isFocused={isFocused} setIsFocused={setIsFocused} json={props.json} castellers={castellers} hoveredUser={hoveredUser} setHoveredUser={setHoveredUser} />
                            </div>
                            <div className="swiper-slide llista-container castellers-confirmats">
                                <LlistaCastellers isModel={isModel} slideNum={2} name="confirmats" etiquetaUsers={etiquetaUsers} etiquetes={etiquetes} filter={confirmatsFilter} {...props} nameSearch={nameSearch} setNameSearch={setNameSearch} showBuscar={showBuscar} setShowBuscar={setShowBuscar} isFocused={isFocused} setIsFocused={setIsFocused} json={props.json} castellers={castellers} hoveredUser={hoveredUser} setHoveredUser={setHoveredUser} />
                            </div>
                            <div className="swiper-slide llista-container castellers-no-confirmats">
                                <LlistaCastellers slideNum={3} name="no confirmats" etiquetaUsers={etiquetaUsers} etiquetes={etiquetes} filter={noConfirmatsFilter} {...props} nameSearch={nameSearch} setNameSearch={setNameSearch} showBuscar={showBuscar} setShowBuscar={setShowBuscar} isFocused={isFocused} setIsFocused={setIsFocused} json={props.json} castellers={castellers} hoveredUser={hoveredUser} setHoveredUser={setHoveredUser} />
                            </div>

                            <div className="swiper-slide llista-container castellers-no-venen">
                                <LlistaCastellers slideNum={4} name="no venen" etiquetaUsers={etiquetaUsers} etiquetes={etiquetes} filter={noVenenFilter} {...props} nameSearch={nameSearch} setNameSearch={setNameSearch} showBuscar={showBuscar} setShowBuscar={setShowBuscar} isFocused={isFocused} setIsFocused={setIsFocused} json={props.json} castellers={castellers} hoveredUser={hoveredUser} setHoveredUser={setHoveredUser} />
                            </div>

                            <div className="swiper-slide llista-container castellers-canalla">
                                <LlistaCastellers slideNum={5} name="canalla" etiquetaUsers={etiquetaUsers} etiquetes={etiquetes} filter={canallaFilter} {...props} nameSearch={nameSearch} setNameSearch={setNameSearch} showBuscar={showBuscar} setShowBuscar={setShowBuscar} isFocused={isFocused} setIsFocused={setIsFocused} json={props.json} castellers={castellers} hoveredUser={hoveredUser} setHoveredUser={setHoveredUser} />
                            </div>

                            <div className="swiper-slide llista-container castellers-altres">
                                <LlistaCastellers slideNum={6} name="famílies" etiquetaUsers={etiquetaUsers} etiquetes={etiquetes} filter={filterFamilies} {...props} nameSearch={nameSearch} setNameSearch={setNameSearch} showBuscar={showBuscar} setShowBuscar={setShowBuscar} isFocused={isFocused} setIsFocused={setIsFocused} json={props.json} castellers={castellers} hoveredUser={hoveredUser} setHoveredUser={setHoveredUser} />
                            </div>

                            <div className="swiper-slide llista-container castellers-amagats">
                                <LlistaCastellers slideNum={7} name="amagats" etiquetaUsers={etiquetaUsers} etiquetes={etiquetes} filter={amagatFilter} {...props} nameSearch={nameSearch} setNameSearch={setNameSearch} showBuscar={showBuscar} setShowBuscar={setShowBuscar} isFocused={isFocused} setIsFocused={setIsFocused} json={props.json} castellers={castellers} hoveredUser={hoveredUser} setHoveredUser={setHoveredUser} />
                            </div>
                        </> : <>
                            <div className="swiper-slide llista-container castellers-confirmats">
                                <LlistaCastellers isModel={isModel} slideNum={1} name="etiquetes" etiquetaUsers={etiquetaUsers} etiquetes={etiquetes} filter={confirmatsFilter} {...props} nameSearch={nameSearch} setNameSearch={setNameSearch} showBuscar={showBuscar} setShowBuscar={setShowBuscar} isFocused={isFocused} setIsFocused={setIsFocused} json={props.json} castellers={castellers} hoveredUser={hoveredUser} setHoveredUser={setHoveredUser} />
                            </div>
                            <div className="swiper-slide llista-container castellers-tots">
                                <LlistaCastellers isModel={isModel} slideNum={2} name="tots" etiquetaUsers={etiquetaUsers} etiquetes={etiquetes} filter={() => true} {...props} nameSearch={nameSearch} setNameSearch={setNameSearch} showBuscar={showBuscar} setShowBuscar={setShowBuscar} isFocused={isFocused} setIsFocused={setIsFocused} json={props.json} castellers={castellers} hoveredUser={hoveredUser} setHoveredUser={setHoveredUser} />
                            </div>
                        </>
                    }
                </div>

                {
                    !showBuscar && isDesktop && swiper?.realIndex > 0 &&
                    <>
                        <div onClick={() => swiper?.slidePrev(0)} className="swiper-button-prev"></div>
                        <div onClick={() => swiper?.slideNext(0)} className="swiper-button-next"></div>
                    </>
                }
            </div>
        </>
    );
}

export default Controller;